import { render, staticRenderFns } from "./SelectProblemSetIdDialog.vue?vue&type=template&id=3b867286&scoped=true&"
import script from "./SelectProblemSetIdDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./SelectProblemSetIdDialog.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./SelectProblemSetIdDialog.vue?vue&type=style&index=0&id=3b867286&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b867286",
  null
  
)

export default component.exports