var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"mb-2 text-overline primary--text text--darken-2 d-flex align-center"},[_c('router-link',{staticClass:"font-weight-bold d-flex align-center",attrs:{"tag":"span","role":"button","to":{
          name: 'reports',
        }}},[_vm._v(" Class Reports ")]),_c('span',{staticClass:"mx-2"},[_vm._v("/")]),_c('router-link',{staticClass:"font-weight-bold d-flex align-center",attrs:{"tag":"span","role":"button","to":{
          name: 'courseOverTimePage',
          params: { cxref: _vm.course.id },
          query: { ..._setup.route.query },
        }}},[_vm._v(" "+_vm._s(_vm.course?.name)+" ")]),_c('span',{staticClass:"mx-2"},[_vm._v("/")]),_c('span',{staticClass:"font-weight-bold neutral--text text--darken-3"},[_vm._v(" "+_vm._s(_setup.selectedStudent)+" ")])],1),_c('div',{staticClass:"text-h3"},[_vm._v("Student Progress Report")])]),_c('div',{staticClass:"pa-4 d-flex justify-space-between"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Select Student","item-text":"displayName","item-value":"xref","items":_vm.roster,"flat":"","clearable":"","outlined":"","hide-details":"","menu-props":{ offsetY: true },"return-object":""},model:{value:(_setup.student),callback:function ($$v) {_setup.student=$$v},expression:"student"}})],1),(!_setup.noStudentSelected)?_c('div',{staticClass:"d-flex justify-content-end mt-1"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.TableOptions,{attrs:{"options":_setup.tableOptions},model:{value:(_setup.options),callback:function ($$v) {_setup.options=$$v},expression:"options"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.range)?_c(_setup.DateRangeChip,{attrs:{"range":_vm.range},on:{"select":function($event){return _setup.emit('selectInterval', $event)}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.ReportOptions,{attrs:{"options":[_setup.ReportOption.DOWNLOAD]},on:{"select":_setup.selectOption}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"pa-4"},[(!_setup.noStudentSelected)?_c(_setup.StudentOverTimeReportChart,{attrs:{"value":_setup.student?.displayName,"data":_setup.assignmentData,"courseMembers":_vm.roster}}):_vm._e(),(!_setup.noStudentSelected)?_c(_setup.StudentOverTimeTable,{staticClass:"mt-5",attrs:{"data":_setup.assignmentData.tableData,"tableSummary":_setup.assignmentData.tableSummary,"showColumns":_setup.options,"options":_setup.tableOptions,"studentXref":_setup.studentXrefParam}}):_vm._e(),(_setup.noStudentSelected)?_c(_setup.NoStudentSelectedReport):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }