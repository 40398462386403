import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SearchState } from './types';
import { RootState } from '../types';

export const getDefaultState = (): SearchState => ({
  isDownloadingProblems: false,
  searchResults: [],
  problemCount: 0,
  problemSetCount: 0,
  skillBuilders: [],
  nextPageToken: null,
  skillBuilderNextPageToken: null,
  problemSetsNextPageToken: null,
});

export const state: SearchState = getDefaultState();
const namespaced = true;

export const search: Module<SearchState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
