import { MutationTree } from 'vuex';
import { SearchState } from './types';
import { getDefaultState } from '.';
import { RESET_STATE_MUTATION } from '../types';

export const mutations: MutationTree<SearchState> = {
  [RESET_STATE_MUTATION](state): void {
    Object.assign(state, getDefaultState());
  },
  setSearchResults(state: SearchState, searchResults: string[]): void {
    state.searchResults = searchResults;
  },
  setProblemCount(state: SearchState, numProblems: number): void {
    state.problemCount = numProblems;
  },
  setProblemSetCount(state: SearchState, numProblems: number): void {
    state.problemSetCount = numProblems;
  },
  setSkillBuilders(state: SearchState, skillBuilders: string[]): void {
    state.skillBuilders = skillBuilders;
  },
  setIsDownloadingProblems(state: SearchState, value: boolean): void {
    state.isDownloadingProblems = value;
  },
  setNextPageToken(state: SearchState, value: string | null): void {
    state.nextPageToken = value;
  },
  setSkillBuilderNextPageToken(state: SearchState, value: string | null): void {
    state.skillBuilderNextPageToken = value;
  },
  setProblemSetsNextPageToken(state: SearchState, value: string | null): void {
    state.problemSetsNextPageToken = value;
  },
};
