import { AssignmentSettings } from '@/domain/Assignment';
import { CourseDefinition } from '@/domain/Course';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { IsActiveFilterType } from '@/domain/State';
import { User } from '@/domain/User';
import { coreAxios } from '@/plugins/axios';
import { buildCourseDefinition } from '@/utils/course.util';
import { transformUserProfileToUser } from '@/utils/user.util';

const END_POINT = '/courses';

/////////////////
// CAS Web API //
/////////////////

export interface CourseSearchParams {
  teacher?: string;
  student?: string;
  mentor?: string;
  primaryTeachers?: boolean;
  activeFilter?: IsActiveFilterType;
}

export interface CategoryDefinition {
  lmsUid: string;
  uid: string;
  title: string;
  position?: number;
  weight?: number;
  dropLowest?: boolean;
  isActive: boolean;
}

function findCourses(
  filterParams: CourseSearchParams
): Promise<CourseDefinition[]> {
  return coreAxios.get(`${END_POINT}`, { params: filterParams }).then((res) => {
    return res.data.map(buildCourseDefinition);
  });
}

function getCourseCategories(
  courseXref: string
): Promise<Array<CategoryDefinition>> {
  return coreAxios.get(`${END_POINT}/${courseXref}/categories`).then((res) => {
    return res.data;
  });
}

function getCourseRoster(courseXref: string): Promise<Array<User>> {
  return coreAxios.get(`${END_POINT}/${courseXref}/members`).then((res) => {
    return res.data.map(transformUserProfileToUser);
  });
}

function getCourseSubgroupMembers(
  courseXref: string,
  subgroupXref: string
): Promise<Array<User>> {
  return coreAxios
    .get(`${END_POINT}/${courseXref}/subgroups/${subgroupXref}/members`)
    .then((res) => {
      return res.data.map(transformUserProfileToUser);
    });
}

function getCourseMember(
  courseXref: string,
  memberXref: string
): Promise<User> {
  return coreAxios
    .get(`${END_POINT}/${courseXref}/members/${memberXref}`)
    .then((res) => {
      return transformUserProfileToUser(res.data);
    });
}

export interface AssignData {
  name: string;
  parentProblemSetCeri?: string;
  problemSetCeri: string;
  releaseDate?: number;
  dueDate?: number;
  userXrefs?: string[];
  settings?: Partial<AssignmentSettings>;
  categoryUid?: string;
}

const createCourseAssignment = (
  courseXref: string,
  lmsPtype: LmsProviderType,
  assignData: AssignData
): Promise<string> => {
  return coreAxios
    .post(
      `${END_POINT}/${courseXref}/assignments?lmsPtype=${lmsPtype.id}`,
      assignData
    )
    .then((result) => {
      return result.data;
    });
};

export {
  findCourses,
  getCourseRoster,
  getCourseSubgroupMembers,
  getCourseMember,
  createCourseAssignment,
  getCourseCategories,
};
