import { render, staticRenderFns } from "./OverTimeLandingPage.vue?vue&type=template&id=87df0d72&scoped=true&"
import script from "./OverTimeLandingPage.vue?vue&type=script&lang=ts&setup=true&"
export * from "./OverTimeLandingPage.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./OverTimeLandingPage.vue?vue&type=style&index=0&id=87df0d72&prod&style=true&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87df0d72",
  null
  
)

export default component.exports