import mixpanel from 'mixpanel-browser';
import store from '@/store';
import { UserRole } from '@/domain/User';

// production key (c394493de7e5543717c82fd343388893)
// dev key (06a9963a1a6e3ac8bfc9d06dd0dfe87c)

// production
// TODO change api_host to production nginx address
mixpanel.init('c394493de7e5543717c82fd343388893', {
  api_host: 'https://mxpnl.assistments.org',
});

function trackMixpanel(
  eventType: EventType,
  additionalParams?: Record<string, any>
) {
  const currentUser = store.getters['auth/getCurrentUser'];
  // Check if the user is logged in and is a teacher
  if (
    currentUser &&
    currentUser.xref &&
    (currentUser.roles.includes(UserRole.TEACHER) ||
      currentUser.roles.includes(UserRole.MENTOR) ||
      !currentUser.roles.includes(UserRole.STUDENT))
  ) {
    mixpanel.track(eventType, {
      distinct_id: currentUser.xref,
      timestamp: Date.now(),
      version: '2',
      ...additionalParams,
    });
  }
}

enum AssignmentReportType {
  assignmentReport = 'Assignment Report',
  masteryReport = 'Mastery Report',
  studentDetaisReport = 'Student Details Report',
  tutorReport = 'Tutor Report',
}

enum EventType {
  // Insights Hub
  insightsGradeReportView = 'Viewed Insights Hub Grade Level Report',
  selectedInsightsActivityMode = 'Changed Activity Mode in Insights Hub',
  selectedProblemSetTypesInisghts = 'Selected sub set of problem sets in Insights Hub',
  viewedInsightsModuleData = 'Selected Module in Insights Hub',
  viewedInisghtsDetailedTab = 'Selected Details Tab in Insights Hub',
  reportViewedFromInsights = 'Viewed Assignment Report from Insights Hub', // cannot check if working
  selectedInishgtsAchievementTab = 'Selected the Achievement Tab in Insights Hub',
  selectedAssessmentTypeInsights = 'Selected Assessment type to view in Inishgts Hub',
  // Assignment Report (Default + Problems View)
  reportViewed = 'Report Viewed',
  assignmentReportSwitchToStandardsTable = 'Assignment Report Switch to Standards View',
  assignmentReportPreviewAsStudent = 'Assignment Report Preview As Student',
  assignmentReportStudNotStarted = 'Assignment Report Progress Students Not Started Clicked',
  assignmentReportStudInProgress = 'Assignment Report Progress Students In Progress Clicked',
  assignmentReportStudCompleted = 'Assignment Report Progress Students Completed Clicked',
  assignmentReportStudAssigned = 'Assignment Report Progress Students Assigned Clicked',
  assignmentReportStudNotStartedClosed = 'Assignment Report Progress Students Not Started Closed',
  assignmentReportStudInProgressClosed = 'Assignment Report Progress Students In Progress Closed',
  assignmentReportStudCompletedClosed = 'Assignment Report Progress Students Completed Closed',
  assignmentReportStudAssignedClosed = 'Assignment Report Progress Students Assigned Closed',
  irClick = 'Assignment Report Problem Side Sheet IR Clicked',
  psideClick = 'Assignment Report Problem Side Sheet Overview Clicked',
  assignmentReportOpenProblemSideSheet = 'Assignment Report Open Problem Side Sheet',
  assignmentReportOpenProblemSideSheetIR = 'Assignment Report Open Problem Side Sheet Instructional Recommendation',
  assignmentReportDeleteStudentProgress = 'Assignment Report Delete Student Progress',
  assignmentReportCloseProblemSideSheet = 'Assignment Report Close Problem Side Sheet',
  assignmentReportPerfSumFilter = 'Assignment Report Performance Summary Filter',
  assignmentReportPerfSumRedoToggle = 'Assignment Report Performance Summary Redo Toggle',
  assignmentReportPerfSumStacked = 'Assignment Report Performance Summary Stacked Filter',
  assignmentReportPerfSumHover = 'Assignment Report Performance Summary Hover',
  assignmentReportScoringDialog = 'Assignment Report Scoring Dialog Open/Close',
  masteryReportScoringDialog = 'Mastery Report Scoring Dialog Open/Close',
  assignmentReportCSVDownload = 'Assignment Report CSV Download',
  masteryReportCSVDownload = 'Mastery Report CSV Download',
  assignmentReportUpload = 'Assignment Report Upload',
  masteryReportUpload = 'Mastery Report Upload',
  assignmentReportSortOrder = 'Assignment Report Sort Order',
  assignmentReportToggleRedo = 'Assignment Report Header Toggle Redo',
  assignmentReportScoreChipOpen = 'Assignment Report Score Chip Open',
  assignmentReportScoreChipClose = 'Assignment Report Score Chip Close',
  assignmentReportCWAProblemHover = 'Assignment Report CWA Problem Hover',
  assignmentReportStudentRowHover = 'Assignment Report Student Row Hover',
  assignmentReportStaticRowHover = 'Assignment Report Static Row Hover',
  hideInformationAssignmentReport = 'Information hidden in Assignment Report',
  // Assignment Report (Standards View)
  assignmentReportSwitchProblemsView = 'Assignment Report Switch to Problems View',
  standardsReportCSVDownload = 'Standards Report CSV Download',
  standardsReportSortOrder = 'Standards Report Sort Order',
  standardsReportScoringDialog = 'Standards Report Scoring Dialog Open/Close',
  hideInformationStandardsReport = 'Information hidden in Standards Report',
  // Assignment Report (Student Details View)
  studentDetailsViewed = 'Student Details Viewed',
  assignmentReportBackToAssignmentReport = 'Back to Assignment Report from Student Details',
  studentDetailsOpenProblemSideSheet = 'Student Details Open Problem Side Sheet',
  studentDetailsCloseProblemSideSheet = 'Student Details Close Problem Side Sheet',
  studentDetailsOpenFeedbackSideSheet = 'Student Details Open Feedback Side Sheet',
  studentDetailsCloseFeedbackSideSheet = 'Student Details Close Feedback Side Sheet',
  studentDetailsQuickCommentUpdate = 'Student Details Quick Comment Update', // cannot check fully as QC not up
  // Essay Scoring
  qcCommentSelection = 'Selected QUICK-Comments Suggested Comment', // cannot check fully as QC not up
  qcScoreSelection = 'Selected QUICK-Comments Suggested Score', // cannot check fully as QC not up
  essayScoringUpdate = 'Essay Scoring Table Update', // cannot check fully as QC not up
  essayScoringClicked = 'Clicked on Assess to Essay Scoring Page',
  essayScoringSetting = 'Essay Scoring Page Setting',
  essayScoringDialogOpen = 'Essay Scoring Dialog Open/Close',
  essayScoringHideNames = 'Essay Scoring Table Hide Names',
  essayScoringViewed = 'Essay Scoring Table Viewed',
  essayReportBackToAssignmentReport = 'Back to Assignment Report from Essay Report',
  essayScoringShowQuestion = 'Essay Scoring Table Show Question',
  essayScoringSortOrder = 'Essay Scoring Table Sort Order',
  // My Assignments
  navMyAssignmentsLoaded = 'Nav My Assignments Loaded',
  myAssignmentFiltersUsed = 'My Assignment Filters Used',
  myAssignmentsDownloadMore = 'My Assignments Download More',
  myAssignmentsDetails = 'My Assignments Details',
  myAssignmentsShowMenu = 'My Assignments Show Menu',
  trackDeleteAssignmentClicked = 'Delete Assignment Clicked',
  trackAssignmentDeleted = 'Assignment Deleted',
  trackEditAssignmentClicked = 'Edit Assignment Clicked',
  trackAssignmentUpdated = 'Assignment Updated',
  trackDuplicateAssignmentClicked = 'Duplicate Assignment Clicked',
  trackPrintAssignment = 'Print Assignment',
  trackAssignmentPreviewAsStudent = 'Assignment Preview As Student',
  // Find & Assign
  navFindNAssignLoaded = 'Nav Find n Assign Loaded',
  findNAssignFolderLoaded = 'Find n Assign Folder Loaded',
  lessonPageLoad = 'Lesson Page Load',
  trackAssignDialogShown = 'Assign Dialog Shown',
  trackAssignDialogClosed = 'Assign Dialog Closed',
  trackAssignmentCreated = 'Assignment Created',
  searchBarSkillSelected = 'Search Bar Skill Selected',
  searchProblemSetSuccess = 'Search Problem Set Success',
  searchProblemSetError = 'Search Problem Set Error',
  searchBarSkillProblemTypeFilterChanged = 'Search Bar Skill Problem Type Filter Changed',
  searchBarSkillCurriculaFilterChanged = 'Search Bar Skill Curricula Filter Changed',
  searchBarSkillDownloadProblems = 'Search Bar Skill Download More Problems',
  // My Problem Sets
  navMyProblemSetsLoaded = 'Nav My Problem Sets Loaded',
  trackSaveToMyProblemSetsDialogShown = 'Save To My Problem Sets Dialog Shown',
  trackSaveToMyProblemSetsDialogClosed = 'Save To My Problem Sets Dialog Closed',
  trackProblemSetSavedNew = 'Problem Set Saved New',
  trackProblemSetSavedCurrent = 'Problem Set Saved Current',
  trackProblemSetMoveProblem = 'Problem Set Move Problem',
  trackProblemSetMoveProblemFail = 'Problem Set Move Problem Fail',
  trackProblemSetDeleteProblem = 'Problem Set Delete Problem',
  trackProblemSetShowMenu = 'Problem Set Menu Open/Close',
  trackPSFolderCreated = 'Problem Set Folder Created',
  trackPSFolderUpdated = 'Problem Set Folder Updated',
  trackPSFolderOpen = 'Problem Set Folder Open',
  trackPSFolderProblemSetMoved = 'Problem Set Folder or Problem Set Moved',
  trackPSFolderDeleted = 'Problem Set Folder Deleted',
  //problemSetShown = 'Problem Set Shown',
  editMyProblemSet = 'Edit My Problem Set',
  deleteMyProblemSet = 'Delete My Problem Set',
  duplicateMyProblemSet = 'Duplicate My Problem Set',
  renameMyProblemSet = 'Rename My Problem Set',
  printMyProblemSet = 'Print My Problem Set',
  trackProblemMenuVisibility = 'Problem Menu Open/Close',
  trackProblemSetPreviewAsStudent = 'Problem Set Preview As Student',
  trackProblemPreviewAsStudent = 'Problem Preview As Student',
  trackStudentSupportDialogShown = 'Student Support Dialog Shown',
  trackStudentSupportDialogClosed = 'Student Support Dialog Closed',
  trackStudentSupportCreated = 'Student Support Created',
  trackStudentSupportUpdated = 'Student Support Updated',
  trackStudentSupportDeleted = 'Student Support Deleted',
  trackStudentSupportChanged = 'Student Support Changed',
  // Account Settings
  navAccountSettingsLoaded = 'Nav Account Settings Loaded',
  accountSettingsPreferencesUpdated = 'Account Settings Preferences Updated',
  accountSettingsFeatureSettingsUpdated = 'Account Settings Feature Settings Updated',
  importClassesSuccessful = 'Import Classes Successful',
  syncClassesSuccessful = 'Sync Classes Successful',
  trackLogOut = 'Log Out',
  // Overtime Report
  studentOvertimeReportCSVDownload = 'Student Overtime Report CSV Download',
}

export { AssignmentReportType, EventType, trackMixpanel };
